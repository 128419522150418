<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Liste des Vehicules"
      class="px-5 py-3"
    >
      <v-row
        style="margin: 20px 0"
      >
        <v-col
          cols="12"
          md="2"
          offset-md="1"
        >
          <v-btn
            color="success"
            disabled
            @click="trierDate()"
          >
            Trier par marque
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-btn
            color="success"
            disabled
            @click="trierDate()"
          >
            Trier par modele
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="4"
          offset-md="2"
        >
          <v-text-field
            label="Rechercher"
            color="success"
            hide-details
            style="max-width: 165px; padding-top: 0px;"
          >
            <template
              v-if="$vuetify.breakpoint.mdAndUp"
              v-slot:append-outer
            >
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                small
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              Marque
            </th>
            <th class="primary--text">
              Modele
            </th>
            <th class="primary--text" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="item in items"
            :key="item.modele"
          >
            <td> {{ item.Nom }} </td>
            <td> {{ item.Modele }} </td>
            <td>
              <v-btn
                disabled
                @click="goDetailsVehicule(item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Fiche détails
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>

    <div class="py-3" />
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import {
    mapState,
  } from 'vuex'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [],
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
    },
    created () {
      Vue.axios.get('https://api.espace-jantes.com/allVehiculeMarqueModele').then((response) => {
        response.data.forEach(element => this.items.push(element))
      })
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      goDetailsVehicule: function (infoVehicule) {
        this.$router.push({ name: 'VehiculeDetails', params: { infoVehicule: infoVehicule } })
      },
    },
  }
</script>
